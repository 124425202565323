import _ from 'lodash'
import { 
  Tabs,
  Icon,
  Spin,
  Table,
  Modal,
  Button,
  Upload,
  Collapse,
  Popconfirm,
} from 'antd'
import XLSX from 'xlsx'
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { CSVLink } from "react-csv";
import { bindActionCreators } from 'redux'
import { extendMoment } from 'moment-range'
import AccessControl from '../../components/AccessControl'
import { showNotification } from '../../utils/notification'
import { fetchPlexosConfiguration, uploadNewPlexosConfiguration } from '../../store/actions/plexos'
import Joyride from 'react-joyride'
import './Plexos.css'

extendMoment(moment)

const { Dragger } = Upload
const { TabPane } = Tabs
const { Panel } = Collapse

const initialState = {
  dataset: undefined,
  activePanelKey: [],
  csvData: [],
  isTourRunning: false,
  plexosCsv: undefined,
  plpCsv: undefined
}

const plexosColumn = [
  {
    title: 'Nombre Plexos',
    dataIndex: 'name_key'
  },
  {
    title: "Potencia Máxima",
    dataIndex: 'max_potency',
  },
  {
    title: "Unidad Generadora",
    dataIndex: 'unit_name',
    render: (text, record, index) => (
      `${record.unit_name} (ID: ${record.unit_id})`
    )
  },
  {
    title: "Potencia de Mantenimiento",
    dataIndex: 'maintenance_potency',
  }
]

const plpColumn = [
  {
    title: 'Nombre PLP',
    dataIndex: 'name_key'
  },
  {
    title: "Potencia Máxima",
    dataIndex: 'max_potency'
  },
  {
    title: "Unidad Generadora",
    dataIndex: 'unit_name',
    render: (text, record, index) => (
      `${record.unit_name} (ID: ${record.unit_id})`
    )
  },
  {
    title: "Potencia de Mantenimiento",
    dataIndex: 'maintenance_potency'
  }
]

const tourSteps = [
  {
    title: "Actualizar configuración",
    target: "#update-plexos-plp-panel",
    content: `Aquí podrás subir una nueva configuración Plexos - PLP.`,
    disableBeacon: true
  },
  {
    title: "Configuración actual",
    target: "#current-plexos-config-panel",
    content: `Aquí podrás ver la configuración actual Plexos - PLP.`,
    disableBeacon: true
  },
  {
    title: "Descarga de informe",
    target: "#download-report-buttons",
    content: `Primero debes hacer click para preparar un informe, una vez listo, el botón cambiará
              de estado y podrás hacer click para descargar el informe.`,
    disableBeacon: true
  },
]

class Component extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initialState
    }
  }

  componentDidMount(){
    const {fetchPlexosConfiguration} = this.props
    fetchPlexosConfiguration()
  }

  renderTables = (plexos, plp, readOnly) => {
    const operations = this.getTabOperation()
    return (
      <Tabs 
        defaultActiveKey="plexos"
        tabBarExtraContent={readOnly ? null : operations}
      >
        <TabPane tab={<span>Configuración Plexos</span>} key="plexos">
          <Table columns={plexosColumn} dataSource={plexos}/>
        </TabPane>
        <TabPane tab={<span>Configuración PLP</span>} key="plp">
          <Table columns={plpColumn} dataSource={plp}/>
        </TabPane>
      </Tabs>
    )
  }

  unitToInfotecnicaId = (currentUnit) => {
    const { unit } = this.props
    const entity = Object.entries(unit.byId).find(element => element[1].nombre === currentUnit)
    if(entity) {
      return unit.byId[entity[0]]
    } else {
      return null
    }
  }

  parseConfig = (configs, plexos=false, plp=false) => (
    configs.map(config => {
      const unit = this.unitToInfotecnicaId(config[2])
      if (!unit) {
        return {
          invalid: true
        }
      }
      return {
        name_key: config[0],
        max_potency: config[1],
        unit_id: unit.id,
        unit_name: unit.nombre,
        central_name: unit.central_nombre,
        central_id: unit.id_central,
        maintenance_potency: config[3],
        is_plexos: plexos,
        is_plp: plp
      }
    }).filter(element => !element.invalid)
  )

  updateDataset = (parsed) => {
    this.setState({
      dataset: parsed
    })
  }

  handleClose = () => {
    const { handleCancel } = this.props
    this.setState({...initialState})
    handleCancel() 
  }

  uploaderProps = () => ({
    name: 'file',
    multiple: false,
    accept: '.xls,.xlsx',
    beforeUpload: (file => {
      const reader = new FileReader()
      reader.onload = async ({target}) => {
        const {result} = target
        const workBook = await XLSX.read(result, {type: 'binary'})
        const plexos = workBook.Sheets['PLEXOS']
        const plp = workBook.Sheets['PLP']
        const is_plexos_undefined = typeof plexos === 'undefined'
        const is_plp_undefined = typeof plp === 'undefined'
        if (is_plexos_undefined || is_plp_undefined) {
          showNotification(
            'Error en Lectura del Diccionario',
            `Hoja no encontrada: ${is_plexos_undefined ? 'PLEXOS' : ''} ${is_plp_undefined ? 'PLP' : ''}. 
            Es necesario que el diccionario cuente con las hojas de configuración "PLEXOS" y "PLP"`,
            'error'
          )
        }
        const parsed = {
          plexos: await this.removeDuplicateEntries(
            this.parseConfig(XLSX.utils.sheet_to_json(plexos, {header: 1}), true, false)
          ),
          plp: await this.removeDuplicateEntries(
            this.parseConfig(XLSX.utils.sheet_to_json(plp, {header: 1}), false, true)
          )
        }
        this.updateDataset(parsed)
      }
      reader.readAsBinaryString(file)
      return false // Prevent "Real" Uploading
    })
  })

  removeDuplicateEntries = (arr) => (
    _.uniqWith(arr, this.plexosComparator)
  )

  plexosComparator = (a, b) => (
    a.unit_id === b.unit_id && a.name_key === b.name_key
  )

  getTabOperation = () => {
    const { uploadNewPlexosConfiguration, user } = this.props
    return <AccessControl
      userType={user.userType}
      allowedUserTypes={['superadmin']}
    >
      <Popconfirm
        title="¿Desea aplicar esta configuración?"
        onConfirm={() => {
          uploadNewPlexosConfiguration(this.state.dataset)
          this.setState({...initialState, activePanelKey: ['1']})
        }}
        okText="Sí, Aplicar"
        cancelText="No, Cancelar"
      >
        <Button type='primary'>Guardar Nueva Configuración</Button>
      </Popconfirm>
    </AccessControl>
  }

  handlePanelChange = (activeKey) => {
    this.setState({
      activePanelKey: activeKey
    })
  }

  isHomologated = (unit, dict) => {
    /* At least has ONE Plexos/PLP Key */
    const found = dict.find(entry => entry.unit_id === unit)
    return found ? true : false
  }

  getUnitName = (unit) => {
    try {
        return this.props.unit.byId[unit].nombre
      } catch (e) {
        console.log('ERROR UNIT ******', unit)
      }
     
  }

  generateEntry = (entry, wr) => {
    return {
      ...entry,
      start: wr.programmed_start_date,
      end: wr.programmed_end_date,
      start_real: wr.real_start_date,
      end_real: wr.real_end_date,
      min_potency: 0,
    }
  }

  reportNotHomologated = (unitName, dictType) => {
    showNotification(
      'Unidad no Homologada',
      `La unidad ${unitName} no posee una clave ${dictType}.`,
      'warning'
    )
  }
  
  getReportEntries = (dictionary, dictType) => {
    const report = {}
    const { idOrder, byId } = this.props.workRequests
    idOrder.forEach(requestId => {
      const wr = byId[requestId]
      console.log('wr ********', wr)
      if(wr && wr.installation_type === 'central' && wr.current_status.name === 'Aprobado') { // Will Ignore Lines and WR should be defined
      byId[requestId].sub_installation.forEach(unit => {
        console.log('unit ********', unit)
        const unitName = this.getUnitName(unit) 
        console.log('unitName ********', unitName)
        const isHomologated = this.isHomologated(unit, dictionary)
        if (!isHomologated) {
          this.reportNotHomologated(unitName, dictType)
        } else {
          const entries = dictionary.filter(entry => entry.unit_id === unit)
          entries.forEach(entry => {
            const new_entry = this.generateEntry(entry, wr)
            if(entry.name_key in report) {
              report[entry.name_key].push(new_entry)
            } else {
              report[entry.name_key] = [new_entry]
            }
          })
         }
       })
      } 
    })
    return report
  }

  reduceSet = (array) => {
    /* Array MUST BE sorted */
    const sortedArray = _.sortBy(array, ['start', 'end'])
    const withRangeArray = sortedArray.map(element => ({
      maxPotency: element.max_potency,
      maintenancePotency: element.maintenance_potency,
      period: moment.range(
        moment(element.start.split('T')[0], 'YYYY-MM-DD'), 
        moment(element.end.split('T')[0], 'YYYY-MM-DD')
      ),
      start_real: element.start_real ? moment(element.start_real.split('T')[0], 'YYYY-MM-DD') : element.start_real,
      end_real: element.end_real ? moment(element.end_real.split('T')[0], 'YYYY-MM-DD') : element.end_real,
    }))
    const results = withRangeArray.reduce((previous, current) => {
      if(_.isEmpty(previous)) {
        return [current]
      } else {
        const previousAccumulator = [...previous]
        const lastElement = previousAccumulator.pop()
        if (current.period.isSame(lastElement.period)) {
          return [
            ...previousAccumulator, {
              ...lastElement,
              maintenancePotency: current.maintenancePotency + lastElement.maintenancePotency
              }
            ]
        } else {
          if(!current.period.overlaps(lastElement.period)){
            return [...previousAccumulator, lastElement, current]
          } else {
            const firstPart = lastElement.period.subtract(current.period)
            const intersection = lastElement.period.intersect(current.period)
            const tail = current.period.subtract(intersection)
            const willPush = [
              {
                ...current,
                period: intersection,
                maintenancePotency: current.maintenancePotency + lastElement.maintenancePotency
              }
            ]
            if (firstPart.length > 0) {
              willPush.push({
                ...lastElement,
                period: firstPart[0]
              })
            }
            if (tail.length > 0) {
              willPush.push({
                ...current,
                period: tail[0]
              })
            } 
            return [...previousAccumulator, ...willPush]
          }
        }
      }
    }, [])
    return results
  }

  mergeSet = (reducedArray) => {
    // arr.splice(index, 1)
    const arrayAccumulator = []
    reducedArray.forEach(entry => {
      let exists = false
      arrayAccumulator.forEach((element, index) => {
        if(element.period.isSame(entry.period)) {
          arrayAccumulator[index].maintenancePotency += entry.maintenancePotency
          exists = true
        }
      })
      if(!exists) {
        arrayAccumulator.push(entry)
      }
    })
    return arrayAccumulator
  }

  generateReport = (dictionary, dictType, stateKey) => {
    let accumulator = []
    const dateFormat = 'DD-MM-YYYY'
    console.log('entrando a generateReport **********')
    console.log('INFO PARAMETROS dictionary',dictionary)
    console.log('INFO PARAMETROS dictType',dictType)
    const report = this.getReportEntries(dictionary, dictType)
    console.log({report})
    Object.entries(report).forEach(([key, value]) => {
      const flattenedArray = this.reduceSet(value)
      const mergedArray = this.mergeSet(flattenedArray)
      console.log({flattenedArray})
      console.log({mergedArray})
      const mergedAndSorted = _.sortBy(mergedArray, ['period.start', 'period.end'])
      console.log({mergedAndSorted})
      const toCsvRow = mergedAndSorted.map(entry => {
        console.log({entry})
        const MaxPotencyAvailable = entry.maxPotency - entry.maintenancePotency
        return [
          `${key}`,
          `${entry.period.start.format(dateFormat)}`,
          `${entry.period.end.format(dateFormat)}`, 
          `${entry.start_real ? entry.start_real.format(dateFormat) : entry.start_real}`,
          `${entry.end_real ? entry.end_real.format(dateFormat) : entry.end_real}`,
          '0',
          `${MaxPotencyAvailable >= 0 ? MaxPotencyAvailable : 0}`
        ]
      })
      accumulator = [...accumulator, ...toCsvRow]
    })
    const headers = [`Nombre_${dictType}`, 'Fecha_Inicio_Programado', 'Fecha_Fin_Programado','Fecha_Inicio_Efectivo','Fecha_Fin_Efectivo', 'Potencia_min', 'Potencia_max_disponible']
    this.setState({
      [stateKey]: [headers, ...accumulator]
    })
  }

  getCurrentConfig = (plexos, readOnly) => {
    return  <Spin spinning={plexos.isLoadingConfig} tip="Cargando Configuración Actual">
              {
                this.renderTables(plexos.plexos, plexos.plp, readOnly)
              }
            </Spin>
  }

  render() {
    const { user, isVisible, plexos } = this.props
    const { dataset, plexosCsv, plpCsv, isTourRunning } = this.state
    const modalTitle = (
      <>
        <span
          style={{marginRight: '8px'}}
        >
          Informe Plexos - PLP
        </span>
        <Popconfirm
          trigger="hover"
          placement="bottom"
          icon={<Icon type="question-circle" theme="filled"/>}
          title={"¿Deseas comenzar un tour de ayuda en esta sección?"}
          onConfirm={() => this.setState({isTourRunning: true})}
          okText="Sí"
          cancelText="No">
          <Button
            className={"help-button"}
            type="secondary">
            <Icon type="question-circle"/>
          </Button>
        </Popconfirm>
      </>
    )
    return (
      <Modal
        title={modalTitle}
        visible={isVisible}
        onCancel={() => {
          this.setState({...initialState})
          this.handleClose()
          }
        }
        width='80%'
        destroyOnClose={true}
        className='add-modal modal-plexos'
        okButtonProps={{ style: {display: 'none'}}}
        cancelButtonProps={{ className:'ant-btn-secondary' }}
        >
        <h2>Configuración Plexos - PLP</h2>
        <Spin spinning={plexos.isUpdatingConfig} tip="Cargando Configuración">
        <AccessControl
          userType={user.userType}
          allowedUserTypes={['superadmin']}
        >
          <Joyride
            run={isTourRunning}
            continuous={true}
            showSkipButton={true}
            showProgress={true}
            steps={tourSteps}
            locale={{
              back: "Atrás",
              close: "Cerrar",
              last: "Finalizar",
              next: "Siguiente",
              skip: "Terminar tour"
            }}
            styles={{
              options: {
                zIndex: 1000,
                primaryColor: "#3ca7ad"
              },
            }}
            callback={(tourState) => {
              if(tourState.action === "reset")
                this.setState({isTourRunning: false})
            }}
          />
          <Collapse activeKey={this.state.activePanelKey} onChange={this.handlePanelChange}>
            <Panel
              id="update-plexos-plp-panel"
              key='0'
              header='Actualizar Configuración Plexos - PLP'
            >
              {
                !dataset
                ? <Dragger {...this.uploaderProps()}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Haga Click o Arrastre Archivo Plexos - PLP</p>
                    <p className="ant-upload-hint">
                      Suba un único archivo XLS o XLSX con la configuración "PLEXOS" y "PLP" en hojas distintas. 
                      Este será validado y se mostrará en pantalla. Una vez que confirme que los valores son correctos
                      haga click en "Actualizar" para cargar la nueva configuración.
                    </p>
                  </Dragger>
                : this.renderTables(dataset.plexos, dataset.plp, false)
              }
            </Panel>
            <Panel
              id="current-plexos-config-panel" 
              key="1"
              header="Ver Configuración Actual Plexos - PLP"
            > 
              {
                this.getCurrentConfig(plexos, true)
              }
            </Panel>
          </Collapse>
        </AccessControl>
        </Spin>
        <AccessControl
          userType={user.userType}
          allowedUserTypes={['coordinado', 'coordinador']}
        >
          {
            this.getCurrentConfig(plexos, true)
          }
        </AccessControl>
        <h2>Descarga de Informes</h2>
        {
          plexosCsv
          ? <>
              <CSVLink
                data={this.state.plexosCsv}
                filename={`informe_plexos_${moment().format('DD_MM_YYYY_HH:mm')}.csv`}
                className="ant-btn ant-btn-dashed"
                target="_blank"
                separator=";"
              >
                <Icon type="download"/> Descargar Informe Plexos
              </CSVLink> 
              <Button
                icon='undo'
                shape='circle'
                onClick={() => {
                  this.setState({plexosCsv: undefined})}
                } 
                style={{
                  marginRight: '5px',
                  marginLeft: '5px'
                }}
              />
            </>
          : <Button type='secondary' style={{marginRight: '20px'}} onClick={() => this.generateReport(plexos.plexos, 'Plexos', 'plexosCsv')}>
              Preparar Informe Plexos
            </Button>
        }
        {
          plpCsv
          ? <>
              <CSVLink
                data={this.state.plpCsv}
                filename={`informe_plp_${moment().format('DD_MM_YYYY_HH:mm')}.csv`}
                className="ant-btn ant-btn-dashed"
                target="_blank"
                separator=";"
              >
                <Icon type="download"/> Descargar Informe PLP
              </CSVLink> 
              <Button
                icon='undo'
                shape='circle'
                onClick={() => {
                  this.setState({plpCsv: undefined})}
                } 
                style={{
                  marginRight: '5px',
                  marginLeft: '5px'
                }}
              />
            </>
          : <Button type='secondary' onClick={() => this.generateReport(plexos.plp, 'PLP', 'plpCsv')}>
              Preparar Informe PLP
            </Button>
        }
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  plexos: state.plexos,
  unit: state.entities.unit,
  workRequests: state.entities.workRequests
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPlexosConfiguration,
  uploadNewPlexosConfiguration
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
