import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Table, Icon, Button, Tag, Popconfirm, Form, Modal, Input, Col } from 'antd'
import {
  getUsers,
  openAdminUserModal,
  closeAdminUserModal,
  toggleDisableUser
} from '../../store/actions/user'
import {
  changeUserCompanyRoles,
  changeUserType
} from '../../store/actions/entities'
import {
  getProfileRoles
} from '../../store/actions/profileRoles'
import _ from 'lodash'
import UserModal from '../../components/UserModal'
import { showNotification } from '../../utils/notification'

const { Column } = Table

class UserAdmin extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showHelp: false,
      searchTerm: ''
    }
  }

  componentDidMount () {
    this.props.getUsers()
    this.props.getProfileRoles()
  }

  openModal = (userId) => {
    this.props.openAdminUserModal(userId)
  }

  editUser = () => {
    const { closeAdminUserModal, usersById, selectedUser: selectedUserId, form, changeUserCompanyRoles, changeUserType, currentSessionUserId } = this.props
    const selectedUser = usersById[selectedUserId]
    const profileRoleChanges = []
    form.validateFields((err, values) => {
      if(!err){
        for (const profile of selectedUser.profiles){
          const newRole = values[`profile-${profile.id}-role`]
          profileRoleChanges.push({
            profile_id: profile.id,
            role_id: newRole
          })
        }
        const newUserType = values.user_type
        if (newUserType) {
          if ( selectedUserId === currentSessionUserId) {
            showNotification(
              'Advertencia',
              'Por seguridad NO puede cambiarse el tipo de usuario a sí mismo.',
              'warning'
            )
          } else {
            changeUserType(selectedUserId, values.user_type)
          }
        }
        if(!_.isEmpty(profileRoleChanges.length)) {
          changeUserCompanyRoles(selectedUserId, profileRoleChanges)
        }
        closeAdminUserModal()
      }
    })
  }

  handleSearch = (e) => {
    this.setState({ searchTerm: e.target.value })
  }

  render () {
    const { searchTerm, showHelp } = this.state
    const {
      usersById,
      fetchingUsers,
      rolesById,
      isModalOpen,
      closeAdminUserModal,
      selectedUser,
      currentSessionUserId,
      form,
      toggleDisableUser
    } = this.props

    // Filtrar los usuarios según el término de búsqueda
    const filteredUsers = _.map(usersById, u => ({
      key: u.id,
      ...u
    })).filter(user =>
      Object.values(user).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )

    return (
      <React.Fragment>
        <Row>
          <Col span={12}>
            <h3>
              <span>Administrador de Usuarios</span>
              <Button
                style={{ marginLeft: '6px' }}
                className={"help-button"}
                type="secondary"
                onClick={() => this.setState({ showHelp: true })}
              >
                <Icon type="question-circle" />
              </Button>
            </h3>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Input
              placeholder="Buscar..."
              value={searchTerm}
              onChange={this.handleSearch}
              style={{ width: '200px' }}
            />
          </Col>
        </Row>
        <Row>
          <Table 
            loading={fetchingUsers}
            dataSource={filteredUsers}
          >
            <Column title="Name" dataIndex="name" key="name" />
            <Column title="Email" dataIndex="email" key="email" />
            <Column title="Tipo de usuario" dataIndex="user_type" key="user_type" 
              render={userType => {
                const translation = {
                  superadmin: 'Administrador',
                  coordinador: 'Coordinador General',
                  coordinado: 'Coordinado Encargado'
                }
                return translation[userType]
              }}  
            />
            <Column title="ID Cognito" dataIndex="cognito_id" key="cognito_id" />
            <Column title="Perfiles" dataIndex="profiles" key="profiles" 
              render={profiles => {
                return (
                  profiles.map(p => (
                    <p key={`profile-${p.id}`}>
                      <Tag color="blue" key={`profile-${p.id}`}>
                        {p.company.rut} {p.company.name} - {p.role.name || 'Sin rol asignado'}
                      </Tag>
                    </p>
                  ))
                )
              }}/>
            <Column title="¿Activo?" dataIndex="is_active" key="is_active" 
              render={active => (
                <span>
                  <Icon type={active ? 'check' : 'close'}  />
                </span>
              )}/>
            <Column title="Acciones" dataIndex="id" key="action" 
              render={(userId => {
                const disableRemove = userId === currentSessionUserId
                return (
                  <span>
                    <Button
                      style={{ marginRight: '8px' }}
                      onClick={() => this.openModal(userId)}
                    >
                      <Icon type='edit' />
                    </Button>
                    <Popconfirm
                      disabled={disableRemove}
                      placement="top"
                      title={`¿Está seguro de ${usersById[userId] && usersById[userId].is_active ? 'des' : ''}habilitar este usuario?`}
                      onConfirm={() => toggleDisableUser(userId)} okText="Sí" cancelText="No">
                      <Button disabled={disableRemove}>
                        <Icon type='close-circle' />
                      </Button>
                    </Popconfirm>
                  </span>
                )
              })}/>
          </Table>
        </Row>
        <UserModal 
          isVisible={isModalOpen}
          onCancel={closeAdminUserModal}
          rolesById={rolesById}
          selectedUser={usersById[selectedUser] || {}}
          form={form}
          editUser={this.editUser}
        />
        <Modal
          title="Administrador de Usuarios"
          visible={showHelp}
          okButtonProps={{
            style: { display: 'none' }
          }}
          cancelButtonProps={{
            type: "primary"
          }}
          onCancel={() => this.setState({ showHelp: false })}
          cancelText="Ok"
        >
          <p>En esta sección puedes:</p>
          <ul>
            <li>Editar el rol de un usuario para cada una de las empresas a las que pertenezca.</li>
            <li>Deshabilitar usuarios, excepto a tí mismo.</li>
          </ul>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getUsers,
    openAdminUserModal,
    closeAdminUserModal,
    getProfileRoles,
    changeUserCompanyRoles,
    changeUserType,
    toggleDisableUser
  }, dispatch)

const mapStateToProps = (state) => ({
  usersById: state.entities.users.byId,
  fetchingUsers: state.entities.users.fetchingUsers,
  rolesById: state.entities.profileRoles.byId,
  isModalOpen: state.user.isAdminModalOpen,
  selectedUser: state.user.selectedUser,
  currentSessionUserId: state.user.id
})

const userAdmin = Form.create({ name: 'userAdmin' })(UserAdmin);
export default connect(mapStateToProps, mapDispatchToProps)(userAdmin)
