import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Modal, Table, Button, Icon, Typography, Row, Spin, Form, Input } from 'antd'
import { bindActionCreators } from 'redux'
import {
  applyModification
} from '../../store/actions/workRequests'
import {
  renderDifferencesBetweenVersions
} from '../../utils/modificationUtils/index'
import './ModificationConfirmModal.css'
import { formatDate } from '../../utils/misc'

const { Paragraph, Text } = Typography
const { Item } = Form
const { TextArea } = Input
class ModificationConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    // Initial state: getReasonForNotApplyModal is closed
    this.state = {
      isReasonForNotApplyModalOpen: false,
      recordId: undefined,
      rejectionReason: undefined,
      rejectionReasonCharCount: 0,
      isReasonForNotApplyModalSpinning: false
    };
  }

  handleOpenReasonForNotApplyModal = (record) => {
    this.setState({
      isReasonForNotApplyModalOpen: true,
      recordId: record.id
    });
  }

  handleCloseReasonForNotApplyModal = () => {
    this.setState({
      isReasonForNotApplyModalOpen: false,
      recordId: undefined
    });
  }

  apply = (record) => {
    this.props.applyModification(record.id, true, undefined)
  }

  notApply = () => {
    return this.props.applyModification(this.state.recordId, false, this.state.rejectionReason)
  }

  handleSubmit = async () => {
    let has_error
    this.props.form.validateFields((err) => {
      has_error = err
    })

    if (has_error){
      return
    }

    this.setState({
      isReasonForNotApplyModalSpinning: true
    });

    await this.notApply();

    this.setState({
      isReasonForNotApplyModalSpinning: false
    });
  }

  updateTextArea = ({ target }) => {
    const { value } = target
    this.setState({
      rejectionReason: value,
      rejectionReasonCharCount: value.length
    })
  }

  getColumns = () => {
    const isSuperAdmin = this.props.user.userType === 'superadmin'
    let columns = [{
      title: 'Modificación',
      dataIndex: 'change',
      render: (text, record) => {
        if (record.is_deletion) {
          return (
            <>
              <Icon type="delete" /> {`Eliminar Solicitud ${record.work_request.correlative}`} <br />
              <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                {`Razón: ${record.reason}`}
              </Paragraph>
            </>
          )
        } else {
          const {
            central,
            unit,
            line,
            section,
            substation,
            sub_barra,
            sub_pano,
            sub_transformadores2D,
            sub_transformadores3D,
            sub_bancoscondensadores,
            sub_sistcomper,
            sub_compensadoresactivos,
            sub_condensadoresserie,
            sub_condensadoressincronos,
            sub_reactores,
            sub_medfacturacion
          } = this.props
          const entities = {
            central,
            unit,
            line,
            section,
            substation,
            sub_barra,
            sub_pano,
            sub_transformadores2D,
            sub_transformadores3D,
            sub_bancoscondensadores,
            sub_sistcomper,
            sub_compensadoresactivos,
            sub_condensadoresserie,
            sub_condensadoressincronos,
            sub_reactores,
            sub_medfacturacion
          }
          return renderDifferencesBetweenVersions(record, entities)
        }
      }
    },
    {
      title: 'Solicitante',
      dataIndex: 'user',
      render: (text, record) => {
        const user = record.user
        return `${user.name} (${user.email})`
      }
    },
    {
      title: 'Fecha de Solicitud',
      dataIndex: 'date',
      render: (text, record) => {
        return formatDate(record.created_at)
      }
    }]
    columns.push({
      title: 'Acción',
      dataIndex: '',
      render: (text, record) => {
        return (
          <>
            {isSuperAdmin ?
              <Button
                shape=''
                icon='check'
                type='primary'
                className='modification-button'
                onClick={
                  () => {
                    console.log('record----> ', record)
                    this.apply(record)
                  }
                }>
                Aplicar
              </Button> : null
            }
            <Button
              shape=''
              icon='close'
              type='secondary'
              className='modification-button'
              onClick={
                () => {
                  this.handleOpenReasonForNotApplyModal(record)
                }
              }>
              {isSuperAdmin ? 'Rechazar' : 'Eliminar Solicitud'}
            </Button>
          </>
        )
      }
    })
    return columns
  }

  render() {
    const { visible, handleCancel, modifications, work_request, form } = this.props;
    const { getFieldDecorator } = form;
    if (_.isEmpty(modifications)) {
      return '';
    }

    return (
      <>
        <Modal
          title="Confirmar Cambio de Estado: Rechazo de Modificación"
          visible={this.state.isReasonForNotApplyModalOpen}
          destroyOnClose={true}
          onOk={this.handleSubmit}
          maskClosable={false}
          okText='Rechazar'
          onCancel={this.handleCloseReasonForNotApplyModal}
          width='600px'
          okButtonProps={{ disabled: this.state.isReasonForNotApplyModalSpinning }}
          cancelButtonProps={{ disabled: this.state.isReasonForNotApplyModalSpinning }}
        >
          <Spin spinning={this.state.isReasonForNotApplyModalSpinning}>
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                <Item
                  label="Indique el Motivo de Rechazo"
                  name="reason"
                  rules={[{ required: true, message: 'Debe indicar el motivo de rechazo' }]}
                >
                  {getFieldDecorator('reason', {
                    rules: [{ required: true, message: 'Debe indicar el motivo de rechazo' }]
                  })(
                    <TextArea
                      rows={4}
                      maxLength={7000}
                      style={{ width: '100%' }}
                      onChange={this.updateTextArea}
                    />
                  )}
                </Item>
              </Row>
            </Form>
          </Spin>
          <Row gutter={[16, 26]}>
            <Text>Indique por qué se rechaza la solicitud de modificación({this.state.rejectionReasonCharCount}/7000)</Text>
          </Row>
        </Modal>

        <Modal
          title={`Modificaciones Pendientes para la Solicitud ${work_request.correlative}`}
          visible={visible}
          destroyOnClose={true}
          onCancel={handleCancel}
          width={'80%'}
          className='modifications-modal'
          maskClosable={false}
          footer={<Button type="primary" onClick={handleCancel}>Cerrar</Button>}
        >
          <Table columns={this.getColumns()} dataSource={modifications} />
        </Modal>
      </>
    )
  }
}

const WrappedModificationConfirmModal = Form.create()(ModificationConfirmModal);

const mapStateToProps = (state) => ({
  user: state.user,
  central: state.entities.central,
  unit: state.entities.unit,
  line: state.entities.line,
  section: state.entities.section,
  substation: state.entities.substation,
  sub_barra: state.entities.sub_barra,
  sub_pano: state.entities.sub_pano,
  sub_transformadores2D: state.entities.sub_transformadores2D,
  sub_transformadores3D: state.entities.sub_transformadores3D,
  sub_bancoscondensadores: state.entities.sub_bancoscondensadores,
  sub_sistcomper: state.entities.sub_sistcomper,
  sub_compensadoresactivos: state.entities.sub_compensadoresactivos,
  sub_condensadoresserie: state.entities.sub_condensadoresserie,
  sub_condensadoressincronos: state.entities.sub_condensadoressincronos,
  sub_reactores: state.entities.sub_reactores,
  sub_medfacturacion: state.entities.sub_medfacturacion
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  applyModification
}, dispatch)



export default connect(mapStateToProps, mapDispatchToProps)(WrappedModificationConfirmModal)