import React from 'react'
import _ from 'lodash'
import {
  Icon, List, Typography
} from 'antd'
import moment from 'moment'
import './modificationUtils.css'

const { Item } = List
const { Text } = Typography

export const central = {
  mainId: 'central',
  mainText: 'Central',
  subId: 'unit',
  subText: 'Unidad',
  connectionId: 'central'
}

export const line = {
  mainId: 'line',
  mainText: 'Línea',
  subId: 'section',
  subText: 'Sección',
  connectionId: 'linea'
}


export const substation = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_barra',
  subText: 'Elementos',
  connectionId: 'subestacion'
}


export const substation_sub_barra = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_barra',
  subText: 'Elementos',
  connectionId: 'subestacion'
}

export const substation_sub_pano = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_pano',
  subText: 'Elementos',
  connectionId: 'subestacion'
}

export const substation_sub_transformadores2D = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_transformadores2D',
  subText: 'Transformadores 2D',
  connectionId: 'subestacion'
}

export const substation_sub_transformadores3D = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_transformadores3D',
  subText: 'Transformadores 3D',
  connectionId: 'subestacion'
}

export const substation_sub_bancoscondensadores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_bancoscondensadores',
  subText: 'Bancos Condensadores',
  connectionId: 'subestacion'
}

export const substation_sub_sistcomper = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_sistcomper',
  subText: 'Sistemas de Compensación Estática de Reactivos',
  connectionId: 'subestacion'
}

export const substation_sub_compensadoresactivos = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_compensadoresactivos',
  subText: 'Compensadores Activos',
  connectionId: 'subestacion'
}

export const substation_sub_condensadoresserie = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_condensadoresserie',
  subText: 'Condensadores Serie',
  connectionId: 'subestacion'
}

export const substation_sub_condensadoressincronos = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_condensadoressincronos',
  subText: 'Condensadores Síncronos',
  connectionId: 'subestacion'
}

export const substation_sub_reactores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_reactores',
  subText: 'Reactores',
  connectionId: 'subestacion'
}

export const substation_sub_medfacturacion = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_medfacturacion',
  subText: 'Medidores de facturación',
  connectionId: 'subestacion'
}

export const mergeArray = (accumulator, incoming) => {
  return [...accumulator, ...incoming]
}

export const printDifferences = (previous, next) => (
  <>
    <Text className='previous-value'>{`${previous} `}</Text><Icon type='arrow-right' /><Text className='next-value' strong>{` ${next}`}</Text>
  </>
)

export const printTittle = (text) => (
  <>
    <Icon type='edit' /> <Text strong>{`${text} `}</Text>
  </>
)

export const timeFormat = 'MMMM DD, YYYY HH:mm'

export function translateInstallationType (item) {
  //return item === 'central' ? 'Central' : 'Línea'
  return item === 'central' ? 'Central' : item === 'line' ? 'Línea' : 'Subestación'
}

export function nameInstalation (isMain, access, id, entitites) {
  return isMain
    ? (entitites[access.mainId].byId[id].nombre)
    : (entitites[access.subId].byId[id].nombre)
}

export function showAffectedDealers (items) {
  const affectedDealers = items.map(item => {
    return `Pérdida de ${item.power} MW para ${item.company.name}, Región ${item.region.name}`
  })
  return affectedDealers.length > 0 ? affectedDealers.join(', ') : 'Vacio'
}

export const getInstallationTypeDiff = (previous, next) => {
  const oldValue = previous.installation_type
  const newValue = next.installation_type
  const accumulator = []
  if (oldValue !== newValue) {
    accumulator.push({
      title: printTittle('Tipo de Instalación'),
      description: printDifferences(translateInstallationType(oldValue), translateInstallationType(newValue))
    })
  }
  return accumulator
}

export const getInstallationDiff = (previous, next, installationDiff, entities) => {
  const oldValue = previous.installation
  const newValue = next.installation
  const accumulator = []

  if (oldValue !== newValue) {
    accumulator.push({
      title: printTittle('Instalación'),
      description: printDifferences(nameInstalation(true, installationDiff.current, oldValue, entities), nameInstalation(true, installationDiff.next, newValue, entities))
    })
  }
  return accumulator
}

export const getSubInstallationDiff = (previous, next, installationDiff, entities) => {
  const oldValue = previous.sub_installation
  const newValue = next.sub_installation
  const accumulator = [] 
  if (!_.isEqual(oldValue.sort(), newValue.sort())) {
    const oldInstSub = oldValue.map(id => nameInstalation(false, installationDiff.current, id, entities))
    const newInstSub = newValue.map(id => nameInstalation(false, installationDiff.next, id, entities))
    accumulator.push({
      title: printTittle('Sub Instalación'),
      description: printDifferences(oldInstSub.join(', '), newInstSub.join(', '))
    })
  }
  return accumulator
}

export const getWorkDescriptionDiff = (previous, next) => {
  const oldValue = previous.work_description
  const newValue = next.work_description
  const accumulator = []
  if (oldValue !== newValue) {
    accumulator.push({
      title: printTittle('Descripción de los Trabajos'),
      description: printDifferences(oldValue, newValue)
    })
  }
  return accumulator
}

export const getRiskDiff = (previous, next) => {
  const oldValue = previous.risk
  const newValue = next.risk
  const accumulator = []
  if (oldValue !== newValue) {
    accumulator.push({
      title: printTittle('Nivel de Riesgo'),
      description: printDifferences(oldValue, newValue)
    })
  }
  return accumulator
}

export const getDateDiff = (previous, next, key, label) => {
  const oldValue = moment(previous[key])
  const newValue = moment(next[key])
  const accumulator = []
  if (!oldValue.isSame(newValue)) {
    const previousDate = oldValue.format('DD/MM/YYYY HH:mm')
    const nextDate = newValue.format('DD/MM/YYYY HH:mm')
    accumulator.push({
      title: printTittle(label),
      description: printDifferences(previousDate, nextDate)
    })
  }
  return accumulator
}

export const getDateDiffDias = (dias_inicio, dias_fin, label) => {

  const accumulator = []
  accumulator.push({
    title: printTittle(label),
    description: printDifferences(dias_inicio + ' dia(s)', dias_fin + ' dia(s)')
    })

  return accumulator
}

export const getPostponableDiff = (previous, next) => {
  const oldValue = previous.postponable
  const newValue = next.postponable
  const accumulator = []
  if (oldValue !== newValue) {
    accumulator.push({
      title: printTittle('Postergable?'),
      description: printDifferences(oldValue ? 'Sí' : 'No', newValue ? 'Sí' : 'No')
    })
  }
  return accumulator
}

export const getOptionalCommentsDiff = (previous, next) => {
  const oldValue = previous.additional_comments || 'Vacío'
  const newValue = next.additional_comments || 'Vacío'
  const accumulator = []
  if (oldValue !== newValue) {
    accumulator.push({
      title: printTittle('Comentarios Adicionales'),
      description: printDifferences(oldValue, newValue)
    })
  }
  return accumulator
}

export const getAffectedDealersDiff = (previous, next) => {
  const accumulator = []
  const oldValue = previous.affected_dealers || []
  const newValue = next.affected_dealers || []
  if (!_.isEqual(oldValue.sort(), newValue.sort())) {
    accumulator.push({
      title: printTittle('Distribuidores Afectados'),
      description: printDifferences(showAffectedDealers(oldValue), showAffectedDealers(newValue))
    })
  }
  return accumulator
}

export const getConsumptionTypeDiff = (previous, next) => {
  const accumulator = []
  const oldValue = previous.consumption_type
  const newValue = next.consumption_type
  if (!(oldValue === newValue)) {
    accumulator.push({
      title: printTittle('Tipo de Consumo'),
      description: `${oldValue || 'Vacio'} ⟶ ${newValue || 'Vacio'}`
    })
  }
  return accumulator
}

export const getRequestDiff = (key, previous, next, installationDiff, entities) => {
  let accumulator = []
  switch (key) {
    case 'installation_type':
      accumulator = mergeArray(accumulator, getInstallationTypeDiff(previous, next))
      break
    case 'installation':
      accumulator = mergeArray(accumulator, getInstallationDiff(previous, next, installationDiff, entities))
      break
    case 'sub_installation':
      accumulator = mergeArray(accumulator, getSubInstallationDiff(previous, next, installationDiff, entities))
      break
    case 'work_description':
      accumulator = mergeArray(accumulator, getWorkDescriptionDiff(previous, next))
      break
    case 'risk':
      accumulator = mergeArray(accumulator, getRiskDiff(previous, next))
      break
    case 'programmed_end_date':
      accumulator = mergeArray(accumulator, getDateDiff(previous, next, key, 'Fechas de Término'))
      const new_programmed_start_date_ini = new Date(previous.programmed_start_date).getTime()
      const new_programmed_end_date_ini = new Date(previous.programmed_end_date).getTime()
      const dif_ini = new_programmed_end_date_ini - new_programmed_start_date_ini
      const dias_inicio = Math.round(dif_ini/(1000*60*60*24))
      
      const new_programmed_start_date_fin = new Date(next.programmed_start_date).getTime()
      const new_programmed_end_date_fin = new Date(next.programmed_end_date).getTime() 
      const dif_fin = new_programmed_end_date_fin - new_programmed_start_date_fin
      const dias_fin = Math.round(dif_fin/(1000*60*60*24))

      accumulator = mergeArray(accumulator, getDateDiffDias(dias_inicio, dias_fin, 'Duración de Trabajos'))

      break      
    case 'programmed_start_date':
      accumulator = mergeArray(accumulator, getDateDiff(previous, next, key, 'Fechas de Inicio'))
    break

    case 'postponable':
      accumulator = mergeArray(accumulator, getPostponableDiff(previous, next))
      break
    case 'alternative_start_date':
      accumulator = mergeArray(accumulator, getDateDiff(previous, next, key, 'Fechas de Inicio Alternativas'))
      break
    case 'alternative_end_date':
      accumulator = mergeArray(accumulator, getDateDiff(previous, next, key, 'Fechas de Término Alternativas'))
      break
    case 'additional_comments':
      accumulator = mergeArray(accumulator, getOptionalCommentsDiff(previous, next))
      break
    case 'consumption_type':
      accumulator = mergeArray(accumulator, getConsumptionTypeDiff(previous, next))
      break
    case 'affected_dealers':
      accumulator = mergeArray(accumulator, getAffectedDealersDiff(previous, next))
      break
    default:
      break
  }
  return accumulator
}

export function renderDifferencesBetweenVersions (record, entities) {
  const changes = record.modified_values
  const workRequest = record.work_request
  //5944
  //const keys = Object.keys(changes).sort()  
  const keys = Object.keys(changes).reverse()
  let changeList = []
  const installationDiff = {
    current: workRequest.installation_type === 'central' ? central : workRequest.installation_type === 'line' ? line : substation_subtype(workRequest.installation_subtype),
    //next: changes.installation_type === 'central' ? central : line
    next: changes.installation_type === 'central' ? central : changes.installation_type === 'line' ? line : substation_subtype(changes.installation_subtype)
  }
  keys.forEach(key => {
    changeList = mergeArray(changeList, getRequestDiff(key, workRequest, changes, installationDiff, entities))
  })
  return (
    <>
      <List
        itemLayout='horizontal'
        dataSource={changeList}
        renderItem={item => (
          <Item>
            <Item.Meta
              title={item.title}
              description={item.description}
            />
          </Item>
        )}
      />
    </>
  )
}
export function substation_subtype(substation) {
  const substationMap = {
    11: substation_sub_barra,
    13: substation_sub_pano,
    16: substation_sub_transformadores2D,
    17: substation_sub_transformadores3D,
    20: substation_sub_bancoscondensadores,
    21: substation_sub_sistcomper,
    25: substation_sub_compensadoresactivos,
    22: substation_sub_condensadoresserie,
    23: substation_sub_condensadoressincronos,
    24: substation_sub_reactores,
    33: substation_sub_medfacturacion,
  };

  return substationMap[substation] || substation_sub_barra;
}
