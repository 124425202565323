import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Table, Icon, Button, Modal, Form, Input, Col } from 'antd'
import {
  fetchCompanies,
  changeCompany,
  fetchInfotecnicaCompanies
} from '../../store/actions/entities'
import FormItem from '../../components/FormItem'
import SelectCompany from '../../components/Admin/SelectCompany'


const { Column } = Table

class CompanyAdmin extends React.Component {
  constructor(props){
    super(props)
    this.initialState = {
      isModalOpen: false,
      currentCompany: {},
      showHelp: false,
      searchTerm: ''
    }
    this.state = {
      ...this.initialState
    }
  }

  componentDidMount () {
    this.props.fetchCompanies()
    this.props.fetchInfotecnicaCompanies()
  }

  openModal = (company) => {
    this.setState({
      currentCompany: company,
      isModalOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      ...this.initialState,
      searchTerm: this.state.searchTerm // Preservar el término de búsqueda al cerrar el modal
    })
    this.props.form.resetFields()
  }

  submitForm = company => {
    const { form, changeCompany } = this.props
    form.validateFields((err, values) => {
      if(!err){
        changeCompany(company, values)
        this.closeModal()
      }
    })
  }

  handleSearch = (e) => {
    this.setState({ searchTerm: e.target.value })
  }

  render () {
    const { currentCompany, searchTerm, showHelp, isModalOpen } = this.state
    const {
      companies: { fetchingCompanies },
      companies,
      infotecnicaCompanies,
      form
    } = this.props
    const { getFieldDecorator } = form
    const companyList = companies.allIds.map(id => ({...companies.byId[id], key: id})).sort((a,b) => a.name.localeCompare(b.name))
    
    // Filtrar las empresas según el término de búsqueda
    const filteredCompanyList = companyList.filter(company =>
      Object.values(company).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )

    return (
      <React.Fragment>
        <Row>
          <Col span={12}>
            <h3>
              <span>Administrador de Empresas</span>
              <Button
                style={{ marginLeft: '6px' }}
                className={"help-button"}
                type="secondary"
                onClick={() => this.setState({ showHelp: true })}
              >
                <Icon type="question-circle" />
              </Button>
            </h3>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Input
              placeholder="Buscar Empresa"
              value={searchTerm}
              onChange={this.handleSearch}
              style={{ width: '200px' }}
            />
          </Col>
        </Row>
        <Row>
          <Table
            loading={fetchingCompanies}
            dataSource={filteredCompanyList}
          >
            <Column title="Nombre" dataIndex="name" key="name" />
            <Column title="Rut" dataIndex="rut" key="rut" />
            <Column title="ID Infotécnica" dataIndex="id_infotecnica" key="id_infotecnica" />
            <Column title="ID REUC" dataIndex="id_reuc" key="id_reuc" />
            <Column
              title="Acciones"
              key="actions"
              render={(text, record) => (
                <Button onClick={() => this.openModal(record)}>
                  <Icon type="edit" />
                  Editar ID
                </Button>
              )}
            />
          </Table>
          <Modal
            title="Editar Empresa"
            visible={isModalOpen}
            onOk={() => this.submitForm(currentCompany)}
            onCancel={this.closeModal}
          >
            <Form>
              <p>Nombre: {currentCompany?.name}</p>
              <p>Rut: {currentCompany?.rut}</p>
              <p style={{ marginBottom: '5px' }}>ID REUC: {currentCompany?.id_reuc}</p>
              <FormItem
                label="ID Infotécnica"
                formId="id_infotecnica"
                decorator={getFieldDecorator}
                args={{
                  initialValue: currentCompany?.id_infotecnica,
                  rules: [
                    { required: true, message: 'Indique el ID de la empresa' }
                  ]
                }}
              >
                <SelectCompany
                  companies={infotecnicaCompanies}
                />
              </FormItem>
              <FormItem
                label="Empresas representadas"
                formId="represented_companies"
                decorator={getFieldDecorator}
                args={{
                  initialValue: currentCompany?.represented_companies
                }}
              >
                <SelectCompany
                  mode="multiple"
                  companies={infotecnicaCompanies}
                />
              </FormItem>
            </Form>
          </Modal>
          <Modal
            title="Administrador de Empresas"
            visible={showHelp}
            okButtonProps={{
              style: { display: 'none' }
            }}
            cancelButtonProps={{
              type: "primary"
            }}
            onCancel={() => this.setState({ showHelp: false })}
            cancelText="Ok"
          >
            <p>
              En esta sección puedes:
            </p>
            <ul>
              <li>
                Agregar o editar el ID de Infotécnica de las empresas que
                aparezcan en la lista. Esto permitirá ligar las empresas en esta aplicación a las
                empresas de Infotécnica.
              </li>
              <li>
                Agregar o editar las representaciones de una empresa.
              </li>
            </ul>
          </Modal>
        </Row>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    fetchCompanies,
    fetchInfotecnicaCompanies,
    changeCompany
  }, dispatch)

const mapStateToProps = (state) => ({
  companies: state.entities.companies,
  infotecnicaCompanies: state.entities.infotecnicaCompanies
})

const companyAdmin = Form.create({ name: 'companyAdmin' })(CompanyAdmin);
export default connect(mapStateToProps, mapDispatchToProps)(companyAdmin)
